<template>
  <el-card style="min-height: 45rem">
    <div style="margin-bottom: 1rem">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="searchForm.title" placeholder="商家店名"></el-input>
        </el-col>
        <el-col :span="3">
          <YChooseAreaPro ref="areaPro" :mode="1" @change="chooseArea"></YChooseAreaPro>
        </el-col>
        <el-col :span="3">
          <CateChoose v-model="searchForm.cate_id"></CateChoose>
        </el-col>
        <el-col :span="3">
          <el-input v-model="searchForm.agent_key" placeholder="代理商昵称ID"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button-group>
            <el-button @click="searchGo">搜索</el-button>
            <el-button @click="searchReset">重置</el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
    <div class="desc flex-def" style="margin-bottom: 1rem">
      <div>押金总额：<span>{{ deposit }}</span>元/ <span>{{ deposit_num }}</span>家</div>
      <div style="margin-left: 2rem">已退还：<span>{{ return_fee }}</span>元/ <span>{{ return_num }}</span>家</div>
    </div>
    <el-table v-loading="loading" :data="list">
      <el-table-column prop="id" label="编号" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column width="200" label="商家信息" show-overflow-tooltip>
        <template v-slot="s">
          <div class="flex-def">
            <img :src="s.row.pic | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem">
            <div style="margin-left: .5rem;line-height: 1rem">
              <div style="font-weight: 600">{{ s.row.title }}</div>
              <div style="margin-top: .2rem">
                管理员：{{ s.row.manage_nickname }}({{ s.row.manage_uid }})
              </div>
              <div>
                MOB：{{ s.row.manage_phone }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="register_type" :formatter="registerTypeFormat" label="来源" width="100"
                       show-overflow-tooltip></el-table-column>
      <el-table-column width="100" label="行业" show-overflow-tooltip>
        <template v-slot="s">
          <el-tag type="warning">{{ s.row.cate_title }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :formatter="areaFormat" width="200" label="区域" show-overflow-tooltip></el-table-column>
      <el-table-column width="90" label="代理商" show-overflow-tooltip>
        <template v-slot="s">
          <div v-if="s.row.agent_id > 0" class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
            <img :src="s.row.agent_avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: 3rem">
            <div>{{ s.row.agent_nickname }}({{ s.row.agent_uid }})</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="deposit" width="80" label="已付押金" show-overflow-tooltip></el-table-column>
      <el-table-column label="任务进度" show-overflow-tooltip>
        <template v-slot="s">
          <div v-if="s.row.register_type === 1">
            <div>累计邀请 <span style="color: red">{{ s.row.deposit_need }}</span>人</div>
            <div>还差 <span style="color: red">{{ s.row.deposit_need - s.row.deposit_has }}</span>人</div>
          </div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <el-table-column prop="deposit_last_day" width="100" label="任务剩余天数" show-overflow-tooltip>
        <template v-slot="s">
          <span v-if="s.row.register_type === 1" style="color: red">{{ s.row.deposit_last_day }}天</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="区域代理" show-overflow-tooltip>
        <template v-slot="s">
          <div v-if="s.row.s_a_id > 0" class="flex-def flex-zStart flex-cCenter" style="width: 100%">
            <img :src="s.row.s_a_avatar | tomedia" style="height: 1rem;width: 1rem;border-radius: 1rem">
            <div>{{ s.row.s_a_nickname }}({{ s.row.p_a_id }})（乡镇代）</div>
          </div>
          <div v-if="s.row.d_a_id > 0" class="flex-def flex-zStart flex-cCenter" style="width: 100%">
            <img :src="s.row.d_a_avatar | tomedia" style="height: 1rem;width: 1rem;border-radius: 1rem">
            <div>{{ s.row.d_a_nickname }}({{ s.row.d_a_id }})（区县代）</div>
          </div>
          <div v-if="s.row.c_a_id > 0" class="flex-def flex-zStart flex-cCenter" style="width: 100%">
            <img :src="s.row.c_a_avatar | tomedia" style="height: 1rem;width: 1rem;border-radius: 1rem">
            <div>{{ s.row.c_a_nickname }}({{ s.row.c_a_id }})（市代）</div>
          </div>
          <div v-if="s.row.p_a_id > 0" class="flex-def flex-zStart flex-cCenter" style="width: 100%">
            <img :src="s.row.p_a_avatar | tomedia" style="height: 1rem;width: 1rem;border-radius: 1rem">
            <div>{{ s.row.p_a_nickname }}({{ s.row.p_a_id }})（省代）</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建/编辑时间" width="200">
        <template v-slot="s">
          <div><span style="color: red">创建</span> {{ s.row.created_at | date }}</div>
          <div><span style="color: red">编辑</span> {{ s.row.updated_at | date }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="240" label="操作" show-overflow-tooltip>
        <template slot="header">
          <el-button @click="$router.push({
          name:'card-merchant-edit'
          })">添加商家
          </el-button>
        </template>
        <template v-slot="s">
          <div>
            <el-button @click="seeAgree(s.row.agree)">入驻协议</el-button>
            <el-button @click="$router.push({
            name:'card-merchant-clerk',
            query:{
              merchant_id:s.row.id
            }
            })" type="warning">员工
            </el-button>
            <el-button @click="$router.push({
            name:'card-merchant-coupon',
            query:{
              merchant_id:s.row.id
            }
            })" type="warning">卡券
            </el-button>
          </div>
          <div style="margin-top: .5rem">
            <el-button @click="stop(s.row)" type="danger">停止使用</el-button>
            <el-button @click="$router.push({
            name:'card-merchant-edit',
            query:{
              id:s.row.id
            }
            })" type="primary">编辑
            </el-button>
            <el-button @click="del(s.row)" type="info">删除</el-button>
          </div>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog append-to-body title="购买入驻协议" :visible.sync="agreeBox" destroy-on-close>
      <div style="padding: 1rem">
        <div v-html="agreeContent"></div>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import CateChoose from "@/components/card/Merchant/CateChoose";
import YChooseAreaPro from "@/components/shop/YChooseAreaPro/YChooseAreaPro";
export default {
  name: "manage",
  components: {YChooseAreaPro, CateChoose},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {},
      choose_member: {},
      agreeBox: false,
      agreeContent: "",
      deposit: 0,
      deposit_num: 0,
      return_fee: 0,
      return_num: 0,
      searchForm: {
        title: "",
        p_code: "",
        c_code: "",
        d_code: "",
        s_code: "",
        cate_id: 0,
        agent_key: "",
      },
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    chooseArea(e){
      this.searchForm.p_code = e[0].value;
      this.searchForm.s_code = e[1].value;
      this.searchForm.d_code = e[2].value;
      this.searchForm.s_code = e[3].value;
    },
    searchReset() {
      this.searchForm = {
        title: "",
        p_code: "",
        c_code: "",
        d_code: "",
        s_code: "",
        cate_id: 0,
        agent_key: "",
      }
      this.$refs.areaPro.reset();
      this.searchGo();
    },
    searchGo() {
      this.page = 1;
      this.list = [];
      this.total = 0;
      this.load();
    },
    stop({id}) {
      this.$u.api.card.merchantStatus({
        merchant_id: id,
        status: 3
      }).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    seeAgree(content) {
      if (!content) {
        this.$message.error("无购买订单关联协议")
        return
      }
      this.agreeBox = true;
      this.agreeContent = content;
    },
    del({id}) {
      this.$u.api.card.merchantDel({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    registerTypeFormat(e) {
      let str = "";
      switch (parseInt(e.register_type)) {
        case 1:
          str = "用户端支付注册"
          break;
        case 2:
          str = "管理员后台注册"
          break;
        case 3:
          str = "用户端免费注册"
          break;
      }
      return str;
    },
    areaFormat(e) {
      let arr = [];
      arr.push(e.area_p_label || "")
      arr.push(e.area_c_label || "")
      arr.push(e.area_d_label || "")
      arr.push(e.area_s_label || "")
      return arr.join("")
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.loading = true;
      this.$u.api.card.merchantSearch({
        page: this.page,
        status: 2,
        ...this.searchForm
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
        this.deposit = res.deposit;
        this.deposit_num = res.deposit_num;
        this.return_fee = res.return_fee;
        this.return_num = res.return_num;
        this.loading = false;
      })
    },
  },
}
</script>

<style scoped>
.desc span {
  color: red;
}
</style>